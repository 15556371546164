import './App.css';
import { initializeApp } from "firebase/app";
import AppTheme from './components/AppTheme';
import Header from './components/Header';
import { Routes, Route } from 'react-router-dom';
import Login from './Login';
import { initializeAuth, browserLocalPersistence } from 'firebase/auth';
import Dashboard from './Dashboard';
import { ListingCreationRouterWrapper } from './ListingCreation';
import { QueryClientProvider } from '@tanstack/react-query';
import { OlxRedirect } from './OlxRedirect';
import { Box, CssBaseline } from '@mui/material';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import ImportPage from './ImportPage';
import { getErrorHandlingQueryClient } from './utils/query-client';
import { LogoutPage } from './LogoutPage';
import { useState } from 'react';
import { useSnackbar } from './utils/error-toast-provider';
import { TemplateCreation } from './TemplateCreation';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const auth = initializeAuth(app, { persistence: browserLocalPersistence })

function App() {
  const { showSnackbar } = useSnackbar();
  const [queryClient] = useState(() => getErrorHandlingQueryClient(showSnackbar));

  const [mode, setMode] = useState<'light' | 'dark'>(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode !== 'light' && savedMode !== 'dark') {
      return 'light';
    }
    return savedMode;
  });

  const toggleTheme = () => {
    setMode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('themeMode', newMode);
      return newMode;
    });
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AppTheme mode={mode}>
        <CssBaseline />
        <Header themeMode={mode} toggleThemeMode={toggleTheme} />
        <Box sx={{ paddingY: 3 }}>
          <Routes>
            <Route
              path="/"
              element={
                <AuthenticatedRoute>
                  <Dashboard />
                </AuthenticatedRoute>
              }
            ></Route>
            <Route path="/login" element={<Login />} />
            <Route path="/create" element={
              <AuthenticatedRoute>
                <ListingCreationRouterWrapper />
              </AuthenticatedRoute>
            } />
            <Route path="/templates" element={
              <AuthenticatedRoute>
                <TemplateCreation />
              </AuthenticatedRoute>
            } />
            <Route path="/listings/:id/edit/:platform" element={
              <AuthenticatedRoute>
                <ListingCreationRouterWrapper />
              </AuthenticatedRoute>
            } />
            <Route path="/listings/import" element={
              <AuthenticatedRoute>
                <ImportPage />
              </AuthenticatedRoute>
            } />
            <Route path="/olx/redirect" element={
              <AuthenticatedRoute>
                <OlxRedirect />
              </AuthenticatedRoute>
            } />
            <Route path="/logout" element={
              <AuthenticatedRoute>
                <LogoutPage />
              </AuthenticatedRoute>
            } />
          </Routes>
        </Box>
      </AppTheme>
    </QueryClientProvider >
  );
}

export default App;
